<template>
  <a-layout id="admin-layout-container" class="admin-layout-container">
    <a-layout-sider :trigger="null" collapsible v-model="collapsed">
      <div class="logo" />
      <div class="menu_wrap_">
        <a-menu
          :selectedKeys="selectedKeys"
          :openKeys="expandKeys"
          mode="inline"
          theme="dark"
          :inlineCollapsed="collapsed"
        >
          <template v-for="item in list">
            <a-menu-item
              :class="item.className"
              v-if="!item.children"
              :key="item.key"
            >
              <router-link :to="item.url">
                <span>{{ `${item.title}` }}</span>
              </router-link>
            </a-menu-item>
            <sub-menu v-else :menu-info="item" :key="item.key" />
          </template>
        </a-menu>
        <div class="tel_">
          {{ $t('labels[37]') || '医疗专线' }}：400-169-2988
        </div>
        <div class="tips">
          若在使用查询过程中发现任何问题，请提供录屏或截图，并简述您的问题发送至customerservice@medilink-global.com.cn，我们会第一时间排查修复。感谢贵机构的理解和支持。
        </div>
      </div>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <a-select
          @change="handleProviderChange"
          style="width: 200px;right: 200px;top: 18px;position: absolute;"
          v-model="providerCode"
        >
          <!-- <a-select @change="handleProviderChange" v-model="providerCode"> -->
          <a-select-option
            class="select-break-all"
            v-for="item in providerList"
            :key="item.providerCode"
            :value="item.providerCode"
            >{{ item.longName }}</a-select-option
          >
        </a-select>
        <span
          style="right: 138px;position: absolute;cursor: pointer;"
          @click="changeLanguage"
          >{{ $i18n.locale == 'zh-cn' ? 'English' : '中文' }}</span
        >
        <user-avatar></user-avatar>
      </a-layout-header>
      <a-layout-content
        :style="{
          margin: '0',
          padding: '24px 16px',
          background: '#fff',
          minHeight: '280px',
          borderTop: '1px solid #F6F7F9'
        }"
      >
        <router-view v-if="ifRouterAlive" />
      </a-layout-content>
    </a-layout>
    <a-spin class="loading_" size="large" :spinning="spinning"></a-spin>
  </a-layout>
</template>
<script>
import SubMenu from './components/SubMenu'
import UserAvatar from '@/components/UserAvatar'
import {
  transformMenuData,
  setLang,
  cacheUserInfo,
  getUserInfo
} from '@/utils/authorized'
import Bus from '@/utils/Bus'

export default {
  data() {
    return {
      collapsed: false,
      list: [
        {
          key: '1',
          title: this.$t('menus[6]') || '业务查询',
          url: '/',
          children: [
            {
              key: '1.3',
              title: this.$t('menus[3]') || '福利查询',
              url: '/welfare'
            },
            {
              key: '1.1',
              title: this.$t('menus[4]') || '就诊记录',
              url: '/claims'
            },
            {
              key: '1.2',
              title: this.$t('menus[5]') || '事先授权',
              url: '/preAuth'
            }
          ]
        }
      ],
      expandKeys: ['1', '2', '3', '4'],
      selectedKeys: [],
      routes: [],
      userName: '',
      spinning: false,
      providerList: [],
      providerCode: '',
      ifRouterAlive: true
    }
  },
  watch: {
    providerCode() {
      const u = getUserInfo() || {}
      cacheUserInfo({
        ...u,
        providerCode: this.providerCode
      })
    },
    $route: function() {
      this.toActiveMenuItem()
    }
  },
  components: {
    'sub-menu': SubMenu,
    'user-avatar': UserAvatar
  },
  mounted() {
    this.getMenu()
    Bus.$on('show-loading', () => {
      if (!this.spinning) {
        this.spinning = true
      }
    })
    Bus.$on('hide-loading', () => {
      if (this.spinning) {
        this.spinning = false
      }
    })
    this.fetchProviderList()
  },
  methods: {
    // 查询菜单数据
    getMenu() {
      this.$apis
        .getResourceByUserId()
        .then(res => res.data)
        .then(data => {
          if (data.content && data.content.length) {
            const result = transformMenuData(data.content, true)
            this.list = result
            this.expandKeys = result.map(item => item.value)
            this.toActiveMenuItem()
            Bus.$emit('findAuthorizedButtons')
          }
        })
    },
    handleProviderChange(code) {
      let item = this.providerList.find(item => item.providerCode == code)
      this.$store.commit('providerInfo', item)
      this.$apis
        .changeProvide({
          userId: item.userId
        })
        .then(() => {
          this.ifRouterAlive = false
          this.$nextTick(() => {
            this.ifRouterAlive = true
          })
          this.getMenu()
        })
    },
    fetchProviderList() {
      this.$apis
        .getProviderList()
        .then(res => res.data)
        .then(data => {
          this.providerList = data.data.map(item => {
            return {
              longName: item.longName,
              providerCode: item.providerCode,
              userId: item.userId
            }
          })
          this.providerCode = data.data[0].providerCode
          this.$store.commit('setProviderInfo', data.data[0])
        })
    },
    changeLanguage() {
      let lang = 'zh-cn'
      let langNum = '01'
      if (this.$i18n.locale == 'en-us') {
        this.$i18n.locale = 'zh-cn'
      } else {
        this.$i18n.locale = 'en-us'
        lang = 'en-us'
        langNum = '02'
      }
      setLang(lang)
      this.$apis
        .changeLang({
          language: langNum
        })
        .then(res => res.data)
        .then(() => {
          location.reload()
        })
    },
    toActiveMenuItem() {
      let path = this.$route.path
      let hashArr = []
      let flatList = arr => {
        for (var i = 0; i < arr.length; i++) {
          let item = arr[i]
          hashArr.push(item)
          if (item.children) {
            flatList(item.children)
          }
        }
      }
      flatList(this.list)
      let findItem = hashArr.filter(value => {
        return path == value.url
      })
      if (findItem.length > 0) {
        this.selectedKeys = [findItem[0].key]
      }
    }
  }
}
</script>
<style lang="scss">
.tips {
  color: red;
  padding: 10px;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-popover-placement-bottom {
  .ant-popover-inner-content {
    padding: 0;
  }
}
.ant-layout-header {
  position: relative;
}
.ant-popover-placement-bottom.user_info_popover_ {
  .ant-popover-title {
    min-width: 150px;
  }
  .ant-popover-inner-content {
    padding: 8px 0;
    text-align: center;
  }
  .username_ {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.ant-layout {
  background: #fff !important;
}
#admin-layout-container {
  height: 100%;
  // form about
  .ant-form-item-label {
    text-align: left;
    padding-right: 8px;
  }
  .ant-form-item {
    display: flex;
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
  .ant-btn {
    margin: 3.5px 0px;
  }
  .ant-radio-group-large {
    display: block;
    line-height: 44px;
    // border-bottom: 1px solid #d9d9d9;
    // margin-bottom: 32px;
    .ant-radio-button-wrapper {
      // width: 100px;
      text-align: left;
      padding: 0 22px;
      &:first-child {
        padding-left: 0px;
      }
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      display: none;
    }
    .ant-radio-button-wrapper-checked {
      background: transparent;
    }
    .ant-radio-button-wrapper {
      background: transparent;
      font-size: 13px;
    }
  }
  .ant-radio-button-wrapper {
    border: none;
    border-color: #d9d9d9 !important;
    box-shadow: none;
  }
  //end form about
  // start custom page style
  // end page style
  .menu_wrap_ {
    flex: 1;
    background: #f6f7f9;
    .tel_ {
      padding-left: 12px;
      font-size: 14px;
      color: #333333;
      font-weight: bold;
      margin-top: 42px;
    }
    .ant-menu-submenu-arrow {
      &::before {
        background: #46464f;
      }
      &::after {
        background: #46464f;
      }
    }
  }
  .ant-layout-sider {
    background: #fff;
  }
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    .ant-menu-root {
    }
    .ant-menu {
      background: #f6f7f9;
      .ant-menu-item {
        padding-left: 24px !important;
      }
      .ant-menu-item-selected {
        background: #e3e8eb;
        a {
          color: #00ac51 !important;
        }
      }
      .ant-menu-item,
      .ant-menu-submenu {
        margin-top: 0;
        .ant-menu-submenu-title {
          color: #46464f;
          font-weight: bold;
          padding-left: 12px !important;
        }
        & > a {
          color: #46464f;
          font-weight: bold;
        }
        // .ant-menu-submenu-arrow {
        //   &::before,
        //   &::after {
        //     display: none !important;
        //   }
        // }
      }
    }
    .ant-menu-sub {
      box-shadow: none;
    }
  }
}
.anticon-menu-fold {
  svg {
    color: #46464f;
  }
}
#admin-layout-container .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#admin-layout-container .trigger:hover {
  color: #1890ff;
}

.admin-layout-container .logo {
  width: 106px;
  height: 32px;
  background: #eee;
  margin: 16px 47px;
  background: url('../assets/logo.png') no-repeat;
  background-size: 100% auto;
}
</style>
