import { LOGIN_STATE, USER_INFO, LANG } from './constants'
import Bus from './Bus'

export const isLogined = function() {
  return localStorage.getItem(LOGIN_STATE)
}

export const setLogined = function(val) {
  return localStorage.setItem(LOGIN_STATE, val)
}

export const cacheUserInfo = function(val = null) {
  return localStorage.setItem(USER_INFO, JSON.stringify(val))
}

export const getUserInfo = function() {
  return JSON.parse(localStorage.getItem(USER_INFO) || null)
}

export const getLang = function() {
  return localStorage.getItem(LANG) || 'zh-cn'
}

export const setLang = function(val) {
  return localStorage.setItem(LANG, val)
}

export const transformMenuData = function transformMenuData(
  arr,
  excludeButton,
  disabledButton
) {
  let result = []
  window._authoriedButtons = window._authoriedButtons || []
  for (var i = 0; i < arr.length; i++) {
    let item = arr[i]
    let obj = {
      id: item.id,
      key: item.id.toString(),
      title: item.name,
      name: item.name,
      url: item.url,
      value: item.id.toString(),
      parentId: item.parentId
    }
    if (disabledButton && item.type == '2') {
      obj.disabled = true
    }
    if (item.childEccsResourceVos && item.childEccsResourceVos.length > 0) {
      obj.children = transformMenuData(
        item.childEccsResourceVos,
        excludeButton,
        disabledButton
      )
    }
    // 菜单栏初始化才选择
    if (excludeButton) {
      window._authoriedButtons.push(obj)
    }
    if (excludeButton && item.type === '2') {
      result = null
    } else {
      result.push(obj)
    }
  }
  return result
}

export const filterMenuButtons = function(url = null) {
  window._authoriedButtons = window._authoriedButtons || []
  if (window._authoriedButtons.length < 1) {
    return
  }
  let result = []
  const matchMenu = window._authoriedButtons.find(val => {
    return val.url == url
  })
  result = window._authoriedButtons.filter(val => {
    return matchMenu && val.parentId == matchMenu.id
  })
  result = result.map(val => {
    return val.name
  })
  return result.join(',')
}
const events = {
  EVENTS_FIND_AUTHORIZED_BUTTONS: 'findAuthorizedButtons'
}
export const findAuthorizedButtons = function() {
  Bus.$on(events.EVENTS_FIND_AUTHORIZED_BUTTONS, () => {
    const str = filterMenuButtons(this.$route.path)
    if (str) {
      this.authorizedButtonStr = str
    }
  })
}

export const offFindAuthorizedButtons = function() {
  Bus.$off(events.EVENTS_FIND_AUTHORIZED_BUTTONS)
}
export const emitFindAuthorizedButtons = function() {
  Bus.$emit(events.EVENTS_FIND_AUTHORIZED_BUTTONS)
}
